import { GLOBAL_VAR } from 'src/app/models/global-variable';
import { ERequestStatus } from './enums/enumConstant';
export interface IMenuItem {
  icon?: string;
  icon_type?: 'img' | 'mat';
  title: string;
  route: any | any[];
  child: IChildItem[];
  svgIcon: string;
  permissions?: string[];
  count?: number;
}

export interface IChildItem {
  icon?: string;
  icon_type?: 'img' | 'mat';
  title: string;
  route: any | any[];
  svgIcon: string;
  permissions?: string[];
}

export const MENU = [
  {
    title: 'dashboard',
    route: '/home',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  },
  {
    title: 'admin_service',
    route: '',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  },
  // {
  //   title: 'policy',
  //   route: '/policy',
  //   icon: '',
  //   svgIcon: '',
  //   child: [],
  //   permissions: []
  // },
  {
    title: 'contact',
    route: '/contact',
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  },
  {
    title: 'follow_request',
    route: '/system/all-request/' + ERequestStatus[ERequestStatus.REQUESTING].toLowerCase(),
    icon: '',
    svgIcon: '',
    child: [],
    permissions: []
  }
];

export const SYSTEM_MENU = [
  {
    title: 'dashboard',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/dashboard`,
    icon: '',
    svgIcon: 'dashboard',
    child: [],
    permissions: []
  },
  {
    title: 'request_service',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/request`,
    icon: '',
    svgIcon: 'request-service',
    child: [],
    permissions: []
  },
  {
    title: 'ពង្រាង និងរង់ចាំទូទាត់',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/all-request/${ERequestStatus[ERequestStatus.DRAFT].toLowerCase()}`,
    icon: '',
    svgIcon: 'request-service',
    child: [],
    permissions: []
  },
  {
    title: 'កំពុងត្រួតពិនិត្យ',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/all-request/${ERequestStatus[ERequestStatus.REQUESTING].toLowerCase()}`,
    icon: '',
    svgIcon: 'pending_doc',
    child: [],
    permissions: []
  },
  {
    title: 'ពាក្យស្នើត្រូវកែតម្រូវ',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/all-request/${ERequestStatus[ERequestStatus.RETURN_REQUEST].toLowerCase()}`,
    icon: '',
    svgIcon: 'return_doc',
    child: [],
    permissions: []
  },
  {
    title: 'សំណើត្រូវបដិសេធ',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/all-request/${ERequestStatus[ERequestStatus.REJECTED].toLowerCase()}`,
    icon: '',
    svgIcon: 'reject_doc',
    child: [],
    permissions: []
  },
  {
    title: 'សំណើបានអនុម័ត',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/all-request/${ERequestStatus[ERequestStatus.APPROVED].toLowerCase()}`,
    icon: '',
    svgIcon: 'approve_doc',
    child: [],
    permissions: []
  },
  {
    title: 'ប្រវត្តិរូបខ្ញុំ',
    route: `/${GLOBAL_VAR.PRE_ROUTE_PATH}/profile/detail`,
    icon: '',
    svgIcon: 'person',
    child: [],
    permissions: []
  }
];
